body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
}

.delivery-box {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}

.delivery-box .info {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding: 15px;
  background-color: white;
}

.delivery-box .info:last-child {
  border-bottom: none;
}

.delivery-box .info .Image {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
  border-radius: 100%;
  color: white;
  background-color: black;
  text-align: center;
  line-height: 48px;
}

.delivery-box .info .Image .Icon {
  width: 20px;
  height: 20px;
}

.delivery-box .info div {
  flex: 1;
  overflow: hidden;
}

.delivery-box .info strong {
  display: block;
  color: #333;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
}

.delivery-box .info p {
  margin: 0;
  color: #666;
  font-size: 15px;
}

@media (max-width: 768px) {
  .delivery-box {
    padding: 15px;
  }

  .delivery-box .info {
    flex-direction: column;
    align-items: flex-start;
  }

  .delivery-box .info .Image {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  .delivery-box .info div {
    text-align: left;
    width: 100%;
  }

  .delivery-box .info strong {
    font-size: 16px;
  }

  .delivery-box .info p {
    font-size: 14px;
  }
}

.reviews-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.review {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 calc(33.333% - 40px);
  box-sizing: border-box;
  min-width: 250px;
}

.review-content {
  text-align: center;
}

.review-text {
  font-size: 1.1em;
  margin-bottom: 15px;
}

.review-author {
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .review {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .review {
    flex: 1 1 100%;
  }
}

.contact-box {
  background-color: white;
  padding: 20px 0;
  margin: 10px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
}

.contact-box p {
  margin: 5px 0;
  color: #333;
  font-size: 14px;
}

.contact-box strong {
  display: block;
  margin-top: 10px;
}

.contact-box a {
  text-decoration: none;
}

.contact-box a:hover {
  text-decoration: underline;
}